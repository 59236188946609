import React, { useEffect, useState } from 'react';
import './TasksDueToday.scss';
import { TasksClient } from '../../../API/tasks.client';
import { Text } from '../../Shared/Typography/Typography';
import TaskCompletionToggle from '../../WheelPage/Tabs/TaskReminder/components/TaskCompletionToggle';
import { ChevronDown as ChevronDownIcon, ChevronUp as ChevronUpIcon } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { toasterService } from '../../Shared/Toaster/Toaster.service';

const TasksDueToday = () => {
  const [tasks, setTasks] = useState<any[]>([]);
  const [expanded, setExpanded] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchDueTodayTasks = async () => {
      try {
        const response = await TasksClient.getTasksOverview();
        const fetchedTasks = response?.data || response || [];
        setTasks(fetchedTasks);
      } catch (error) {
        console.error('Error fetching due today tasks:', error);
        setTasks([]);
      }
    };

    fetchDueTodayTasks();
  }, []);

  const handleStatusChange = (taskId: string, newStatus: boolean) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => (task.taskId === taskId ? { ...task, completed: newStatus } : task))
    );
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>, wheelId: string) => {
    event.preventDefault();

    history.push(`/wheel/${wheelId}?taskReminder=true`);
  };

  const handleTaskClick = (task: any) => {
    const isExpired = new Date(task.dueDateTime) < new Date();

    if (isExpired) {
      setTasks((prevTasks) => prevTasks.filter((t) => t.taskId !== task.taskId));
      toasterService.addErrorToast('Task is expired and moved to history.');
    }
  };

  const onToggleOverview = () => {
    setExpanded(!expanded);
  };

  const completedTasks = tasks?.filter((task) => task.completed).length;

  return tasks.length > 0 ? (
    <div className="tasks-due-today">
      <div className="header">
        <Text color="#ffffff" size="18px">
          <Text color="#ffffff" size="18px">
            {`Tasks due today - ${completedTasks}/${tasks.length} done 💪`}
          </Text>
        </Text>
        {tasks.length > 0 && (
          <div className="chevron-btn" onClick={onToggleOverview}>
            {expanded ? <ChevronUpIcon size={24} /> : <ChevronDownIcon size={24} />}
          </div>
        )}
      </div>
      {expanded &&
        tasks.map((task, index) => (
          <div
            key={index}
            className={`due-task-item ${task.completed ? 'completed' : ''}`}
            onClick={() => handleTaskClick(task)}
          >
            <TaskCompletionToggle
              taskId={task.taskId}
              dueDateTime={task.dueDateTime}
              completed={!!task.completed}
              isEditable
              onStatusChange={(newStatus) => handleStatusChange(task.taskId, newStatus)}
            />
            <Text size="16px" weight="400" color="#ffffff" className={task.completed ? 'task-title-complete' : ''}>
              <Link to={`wheel/${task.wheelId}`} onClick={(event) => handleLinkClick(event, task.wheelId)}>
                {task.title}
              </Link>
            </Text>
          </div>
        ))}
    </div>
  ) : null;
};

export default TasksDueToday;
