import moment from 'moment';
import React, { useMemo } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { WheelMemberRole } from '../../../../API/interfaces';
import services from '../../../../API/services';
import { Text } from '../../Typography/Typography';
import { markNotificationAsReadSuccess } from './Notifications.actions';
import { Notification, NotificationTypes } from './Notifications.model';
import { Badge } from './NotificationsButton';
import { CheckCircle, Trash2 } from 'react-feather';

type Props = {
  notification: Notification;
  closeDrawer: () => void;
  onDeleteNotificationsHandler: (id: string) => void;
  isDeleting: boolean;
};

const NotificationItem: React.FC<Props> = ({ notification, closeDrawer, onDeleteNotificationsHandler, isDeleting }) => {
  const {
    id,
    type,
    read,
    initiator,
    createdAt,
    wheelId,
    score,
    comment,
    commentId,
    wheel,
    initiatorId,
    recipientId = '',
    initiatorRole = WheelMemberRole.MEMBER,
    taskTitle,
  } = notification;
  const history = useHistory();
  const dispatch = useDispatch();

  const NotificationTypeContent = useMemo(() => {
    switch (type) {
      case NotificationTypes.memberJoined:
        return <FiUserPlus color="#fff" width={16} height={16} />;

      case NotificationTypes.checkInCreated:
      case NotificationTypes.commentCreated:
        return score;

      case NotificationTypes.taskCreated:
        return <CheckCircle color="#fff" width={16} height={16} />;

      default:
        return null;
    }
  }, [type]);

  const notificationDescription = useMemo(() => {
    switch (type) {
      case NotificationTypes.memberJoined:
        return `Join to wheel "${wheel}"`;

      case NotificationTypes.checkInCreated:
        return 'Completed Check In';

      case NotificationTypes.commentCreated:
        return (
          <>
            <div>Left comment to Check In:</div>
            <div>{`"${comment}"`}</div>
          </>
        );

      case NotificationTypes.taskCreated:
        return taskTitle;

      default:
        return null;
    }
  }, [type]);

  const existingNotificationTime = moment(createdAt).fromNow();

  const onNotificationClick = async () => {
    const redirectedUserId = initiatorRole === WheelMemberRole.ACCOUNTABILITY_BUDDY ? recipientId : initiatorId;
    await services
      .markNotificationAsRead({ id })
      .then(() => dispatch(markNotificationAsReadSuccess(id)))
      .catch((error) => error);
    closeDrawer();
    const historyLink =
      type === NotificationTypes.taskCreated ? `wheel/${wheelId}?taskReminder=true` : `/wheel/${wheelId}`;
    history.push(historyLink, {
      isFromNotification: true,
      initiatorId: redirectedUserId,
      ...(type === 'CommentCreated' && { commentId }),
    });
  };

  const onDeleteNotification = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDeleteNotificationsHandler(id);
  };

  return (
    <NotificationContainer isRead={read} onClick={onNotificationClick}>
      <TypeContainer isDark={type === NotificationTypes.memberJoined || type === NotificationTypes.taskCreated}>
        <Badge isShown={!read} size="8px" />
        {NotificationTypeContent}
      </TypeContainer>
      <DetailsContainer>
        <InfoSection>
          <Text size="14px" color="#2b2e31">
            {type === NotificationTypes.taskCreated ? 'Your Task Reminder' : initiator}
          </Text>
          <Text size="12px" color="#6a777f">
            {notificationDescription}
          </Text>
        </InfoSection>
        <InfoSection>
          <Text size="12px" color="#6a777f">
            {existingNotificationTime}
          </Text>
        </InfoSection>
      </DetailsContainer>
      {/* <Trash2
        color={isDeleting ? '#ccc' : '#fb463b'}
        strokeWidth="2"
        size="16"
        onClick={isDeleting ? undefined : onDeleteNotification}
        style={{
          alignSelf: 'center',
          marginLeft: '10px',
          opacity: isDeleting ? 0.5 : 1,
        }}
      /> */}
    </NotificationContainer>
  );
};

export default NotificationItem;

const NotificationContainer = styled.div<{ isRead: boolean }>`
  display: flex;
  padding: 16px;
  width: 100%;
  cursor: pointer;
  background-color: ${({ isRead }) => (isRead ? '#fff' : '#fffaf3')};
  border-top: 1px solid ${({ isRead }) => (isRead ? '#f8f8f8' : '#fee5c7')};
`;

const TypeContainer = styled.div<{ isDark?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: relative;
  box-shadow: ${({ isDark }) => (isDark ? 'none' : '0 1px 4px 0 rgba(0, 0, 0, 0.16)')};
  background-color: ${({ isDark }) => (isDark ? '#0f2136' : '#fff')};
  font-size: 12px;
  font-weight: bold;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  width: 80%;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 175px;
`;
