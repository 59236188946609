import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Clock } from 'react-feather';
import dayjs, { Dayjs } from 'dayjs';
import './components.scss';

interface DateTimePickerProps {
  taskData: any;
  setTaskData: (updatedData: any) => void;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({ taskData, setTaskData }) => {
  const initialDate = dayjs(taskData.dueDateTime || dayjs().toISOString());
  const initialHour = initialDate.hour();
  const initialMinute = initialDate.minute();

  const [selectedDate, setSelectedDate] = useState<Dayjs>(initialDate);
  const [selectedHour, setSelectedHour] = useState(String(initialHour).padStart(2, '0'));
  const [selectedMinute, setSelectedMinute] = useState(String(initialMinute).padStart(2, '0'));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const currentDate = dayjs();
  const hourOptions = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
  const minuteOptions = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));

  const filteredHourOptions = selectedDate.isSame(currentDate, 'day')
    ? hourOptions.filter((hour) => Number(hour) > currentDate.hour() || Number(hour) === currentDate.hour())
    : hourOptions;

  const filteredMinuteOptions =
    selectedDate.isSame(currentDate, 'day') && Number(selectedHour) === currentDate.hour()
      ? minuteOptions.filter((minute) => Number(minute) >= currentDate.minute())
      : minuteOptions;

  useEffect(() => {
    let updatedDateTime = selectedDate.hour(Number(selectedHour)).minute(Number(selectedMinute)).second(0);

    if (selectedDate.isSame(currentDate, 'day') && updatedDateTime.isBefore(currentDate)) {
      const currentHour = currentDate.hour();
      const currentMinute = currentDate.minute();

      if (
        selectedHour !== String(currentHour).padStart(2, '0') ||
        selectedMinute !== String(currentMinute).padStart(2, '0')
      ) {
        setSelectedHour(String(currentHour).padStart(2, '0'));
        setSelectedMinute(String(currentMinute).padStart(2, '0'));
        updatedDateTime = currentDate;
      }
    }

    setTaskData((prevTaskData) => {
      const newDateTimeISO = updatedDateTime.toISOString();
      if (prevTaskData.dueDateTime !== newDateTimeISO) {
        return {
          ...prevTaskData,
          dueDateTime: newDateTimeISO,
        };
      }
      return prevTaskData;
    });
  }, [selectedDate, selectedHour, selectedMinute, setTaskData, currentDate]);

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setSelectedDate(newDate);
    }
  };

  const openPopups = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopups = () => {
    setAnchorEl(null);
  };

  const handleHourSelect = (hour: string) => {
    if (selectedDate.isSame(currentDate, 'day') && Number(hour) === currentDate.hour()) {
      if (Number(selectedMinute) < currentDate.minute()) {
        setSelectedMinute(String(currentDate.minute()).padStart(2, '0'));
      }
    }
    setSelectedHour(hour);
  };

  const handleMinuteSelect = (minute: string) => {
    if (selectedDate.isSame(currentDate, 'day')) {
      const selectedTime = selectedDate.hour(Number(selectedHour)).minute(Number(minute));
      if (selectedTime.isBefore(currentDate)) {
        setSelectedMinute(String(currentDate.minute()).padStart(2, '0'));
        return;
      }
    }
    setSelectedMinute(minute);
    closePopups();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems="center"
        padding={2}
        sx={{
          width: '270px',
          borderRadius: '8px',
          backgroundColor: 'transparent',
          padding: '0',
          gap: '0',
        }}
      >
        <DateCalendar
          value={selectedDate}
          onChange={handleDateChange}
          shouldDisableDate={(date) => date.isBefore(currentDate, 'day')}
          sx={{
            width: '270px',
            backgroundColor: 'transparent',
            padding: '5px 7px',
            borderRadius: '0',
            '& .MuiPickersCalendarHeader-root': {
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 !important',
              marginTop: '0',
              marginBottom: '0',
            },
            '& .MuiPickersCalendarHeader-label': {
              fontSize: '14px',
              fontWeight: '400',
              color: '#0C2337',
              paddingLeft: '14px',
              fontFamily: 'Rubik,sans-serif',
            },
            '& .Mui-selected': {
              backgroundColor: '#FF5722',
              color: '#fff !important',
              fontSize: '14px',
            },
            '& .MuiButtonBase-root': {
              margin: '0',
              fontFamily: 'Rubik,sans-serif',
            },
            '& .MuiPickersYear-yearButton, & .MuiPickersYear-yearButton': {
              height: '30px',
              width: '60px',
              fontSize: '14px',
            },
            'MuiDayCalendar-header': {
              width: '250px',
            },
            'MuiDayCalendar-weekDayLabel': {
              height: '22px',
            },
            '& .MuiPickersSlideTransition-root': {
              minHeight: '194px',
            },
            '& .MuiYearCalendar-root': {
              width: '270px',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '16px',
            },
            '& .MuiPickersArrowSwitcher-root button': {
              color: '#0C2337',
            },
            '& .MuiPickersDay-root': {
              color: '#0C2337',
            },
            '& .MuiPickersDay-root.Mui-disabled': {
              color: '#ccc',
            },
            '& .MuiPickersDay-root:not(.Mui-selected)': {
              backgroundColor: 'transparent',
            },
          }}
        />

        <Box
          display="flex"
          alignItems="center"
          gap={1}
          onClick={openPopups}
          sx={{
            width: '100%',
            justifyContent: 'start',
            padding: '12px 8px 8px',
            borderTop: '1px solid #ddd',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <div className="date-picker-gap" style={{ marginLeft: '8px' }}>
            <Clock size={16} color="#2b2e31" />
            <Input
              value={`${selectedHour}:${selectedMinute}`}
              className="custom-input"
              style={{
                textAlign: 'center',
                width: '44px',
              }}
            />
          </div>
        </Box>

        {/* Combined Popups for Hours and Minutes */}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopups}
          className="custom-popover"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            padding={2}
            sx={{
              width: '170px',
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#fff',
              padding: '6px',
            }}
          >
            <Box>
              <Box fontSize="14px" textAlign="center" marginBottom="8px" color="#2b2e31" fontFamily="Rubik,sans-serif">
                Hours
              </Box>
              <Stack
                spacing={1}
                sx={{
                  maxHeight: '150px',
                  overflowY: 'auto',
                }}
              >
                {filteredHourOptions.map((hour) => (
                  <Button
                    key={hour}
                    onClick={() => handleHourSelect(hour)}
                    style={{
                      textAlign: 'center',
                      fontSize: '14px',
                      padding: '4px 8px',
                      fontFamily: 'Rubik,sans-serif',
                      fontWeight: '400',
                      color: '#2b2e31',
                    }}
                  >
                    {hour}
                  </Button>
                ))}
              </Stack>
            </Box>

            <Box>
              <Box fontSize="14px" textAlign="center" marginBottom="8px" color="#2b2e31" fontFamily="Rubik,sans-serif">
                Minutes
              </Box>
              <Stack
                spacing={1}
                sx={{
                  maxHeight: '150px',
                  overflowY: 'auto',
                }}
              >
                {filteredMinuteOptions.map((minute) => (
                  <Button
                    key={minute}
                    onClick={() => handleMinuteSelect(minute)}
                    style={{
                      textAlign: 'center',
                      fontSize: '14px',
                      padding: '4px 8px',
                      fontFamily: 'Rubik,sans-serif',
                      fontWeight: '400',
                      color: '#2b2e31',
                    }}
                  >
                    {minute}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Stack>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

export default DateTimePicker;
