import { NotificationsActionTypes, NotificationsReducerState } from './Notifications.model';

const initialState: NotificationsReducerState = {
  notificationsList: [],
};

export const notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case NotificationsActionTypes.GET_NOTIFICATIONS:
      return {
        notificationsList: payload,
      };
    case NotificationsActionTypes.MARK_ALL_NOTIFICATIONS_AS_READ:
      return {
        notificationsList: state.notificationsList.map((notification) => ({
          ...notification,
          read: true,
        })),
      };
    case NotificationsActionTypes.MARK_NOTIFICATION_AS_READ:
      return {
        notificationsList: state.notificationsList.map((notification) => {
          return notification.id === payload
            ? {
                ...notification,
                read: true,
              }
            : notification;
        }),
      };
    case NotificationsActionTypes.DELETE_NOTIFICATIONS:
      return {
        notificationsList: payload ? state.notificationsList.filter((notification) => notification.id !== payload) : [],
      };
    default:
      return state;
  }
};
