import React from 'react';
import './components.scss';
import noTaskImage from '../../../../../Assets/images/no-tasks.png';
import { Text } from '../../../../Shared/Typography/Typography';

interface NoTaskComponentProps {
  message: string;
  showImage?: boolean;
}

const NoTask: React.FC<NoTaskComponentProps> = ({ message, showImage }) => {
  return (
    <div className={showImage ? "no-task" : "no-task-no-img"}>
      <Text size="16px" color="#6a777f" className="mb-1">
        {message}
      </Text>
      {showImage && <img src={noTaskImage} alt="No Tasks" className="no-task-img" />}
    </div>
  );
};

export default NoTask;
