import React from 'react';
import moment from 'moment';
import { FiLogOut } from 'react-icons/fi';
import { MenuItem, Menu } from '@material-ui/core';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import styled from 'styled-components';
import { IconBaseProps } from 'react-icons/lib/cjs/iconBase';
import { Folder as FolderIcon } from 'react-feather';

import { analytics } from '../../../analytics/analytics';

import { WheelChart } from '../../../Templates/WheelChart';

import '../Dashboard.scss';
import './Card.scss';
import { buildTreeByParent, getLatestScoresCreateDateOr } from '../_utils';
import { eDraggableItems } from '../_consts';

import { AccessLabel, OwnershipLabel } from './LabelSection/LabelSection';
import EmptyWheel from './EmptyWheel';
import {
  Wrapper,
  ChartWrapper,
  MainInfo,
  Text,
  AdditionalInfo,
  Labels,
  Text1,
  Text2,
  StyledCopyIcon,
  StyledGearIcon,
  Option,
  Options,
  StyledTrashIcon,
  StyledInvitePlusIcon,
} from './_styled';
import { iProps } from './_types';
import { WHEEL_STRING } from './_consts';
import { AccountPlan, iWheelExt, WheelMemberRole } from '../../../API/interfaces';
import { Guard } from '../../../_utils/Guard';
import of from '../../../_utils/of';

import NestedMenuItem, { Direction } from './NestedMenuItem';
import FolderMenuItem, {
  tProps as tFolderMenuItemProps,
  tPropsWithChild as tFolderMenuItemPropsWithChild,
} from './FolderMenuItem';
import ABWheelLogo from '../../../Assets/images/ab-role-dashboard-card-logo.png';

const ABLogo = styled.img`
  width: 88px;
`;

export const CardDesktop = (props: iProps) => {
  const {
    history,
    wheel,
    user,
    removeWheel,
    id,
    duplicateWheel,
    folders,
    onMoveWheelToFolder,
    leaveWheel,
    inviteToWheel,
  } = props;
  const numberOfAdmins = wheel.members.filter((m) => m.memberRole === WheelMemberRole.ADMIN).length;

  const [{ isDragging }, drag, preview] = useDrag<iWheelExt, any, { isDragging: boolean }>(() => ({
    type: eDraggableItems.WHEEL_CARD,
    item: wheel,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [optionsOpened, setOptionsOpened] = React.useState(false);

  const goToDetails = React.useCallback(() => {
    history.push(`/wheel/${wheel.id}/member/${user._id}`);
  }, []);

  const goToSettings = React.useCallback(() => {
    analytics.wheelSettings();
    history.push(`/wheel/${wheel.id}/settings`);
  }, []);

  const toggle = React.useCallback(() => {
    setOptionsOpened(!optionsOpened);
  }, [optionsOpened]);

  const onDuplicateWheel = React.useCallback(() => {
    setOptionsOpened(false);
    duplicateWheel();
  }, []);

  const onLeaveWheel = React.useCallback(() => {
    setOptionsOpened(false);
    leaveWheel();
  }, []);

  const optionsRef = React.useRef(null);
  const onOptionsClick = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setOptionsOpened(!optionsOpened);
    },
    [optionsOpened]
  );

  const lastCheckin = React.useMemo(() => {
    const date = getLatestScoresCreateDateOr(null, wheel.latestScores);
    return date && moment(date).isValid() ? date : null;
  }, [wheel]);
  // @ts-ignore
  const scores = React.useMemo(() => wheel.latestScores.filter((seg) => seg.score > 0), [wheel]);

  const foldersProps = React.useMemo<tFolderMenuItemProps[]>(
    () =>
      folders.map((folder) => ({
        ...folder,
        optionsOpened,
        containsWheel: folder.wheels.includes(wheel.id),
        onClick: () => onMoveWheelToFolder(folder),
      })),
    [folders, optionsOpened, wheel.id]
  );

  const folderPropsTree = React.useMemo<tFolderMenuItemPropsWithChild[]>(
    () => buildTreeByParent<tFolderMenuItemProps>(foldersProps || []),
    [foldersProps]
  );

  const onDelete = React.useCallback(() => {
    removeWheel();
    setOptionsOpened(false);
  }, []);

  const onInviteToWheel = React.useCallback(() => {
    inviteToWheel();
    setOptionsOpened(false);
  }, []);

  const wheelMember = wheel.members.find((member) => member.userId === user._id);
  const isAccountabilityBuddy = wheelMember?.memberRole === WheelMemberRole.ACCOUNTABILITY_BUDDY;
  const wheelMembersLengthForRole = isAccountabilityBuddy
    ? wheelMember?.accountabilityBuddyFor?.length
    : wheel.members.length;
  const isPersonal = wheel?.isPersonal;

  const hasScore = React.useMemo(() => {
    return wheel.latestScores.some((seg: any) => seg.score);
  }, [wheel.latestScores]);

  return (
    <>
      <DragPreviewImage connect={preview} src={WHEEL_STRING} />

      <Wrapper onClick={goToDetails} ref={drag} isPersonal={isPersonal}>
        <ChartWrapper>
          {isAccountabilityBuddy ? (
            <ABLogo src={ABWheelLogo} />
          ) : (
            <>
              {hasScore ? (
                <WheelChart
                  wheel={wheel}
                  width={88}
                  showScore
                  scoreOptions={{
                    radius: 14,
                    background: '#fff',
                    color: '#2a3e50',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    fontFamily: 'Rubik',
                  }}
                  scores={scores}
                />
              ) : (
                <EmptyWheel />
              )}
            </>
          )}
        </ChartWrapper>

        <MainInfo>
          <Text>{wheel.name}</Text>
          <Text2 className="text-left">{wheel.description}</Text2>
          <Labels>
            <AccessLabel wheel={wheel} />
            {!isPersonal && <OwnershipLabel wheelRole={wheelMember?.memberRole} />}
          </Labels>
        </MainInfo>
        <AdditionalInfo>
          <div className="d-flex flex-column align-items-end">
            {(wheel.isAdmin || isAccountabilityBuddy) && <Text1>{`${wheelMembersLengthForRole} members`}</Text1>}
            <Text1>{`${wheel.checkinsCount} checkins`}</Text1>
          </div>
          {lastCheckin && moment(lastCheckin).isValid() && <Text2>{moment(lastCheckin).fromNow()}</Text2>}
        </AdditionalInfo>

        <Options ref={optionsRef} onClick={isPersonal ? null : onOptionsClick} isPersonal={isPersonal}>
          <StyledBsThreeDotsVertical size="1.5em" selected={optionsOpened} personal={isPersonal} />
        </Options>
      </Wrapper>

      {wheel.isAdmin && (
        <Menu
          anchorEl={optionsRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={optionsOpened}
          onClick={(e) => e.stopPropagation()}
          onClose={() => setOptionsOpened(false)}
          MenuListProps={{ disablePadding: true, style: { minWidth: '200px' } }}
          disableAutoFocusItem
        >
          <MenuItem onClick={goToSettings} className="py-2">
            <StyledGearIcon />
            <Option>Settings</Option>
          </MenuItem>
          {foldersProps.length > 0 && (
            <Guard.Component allow={[AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE]}>
              <NestedMenuItem
                direction={Direction.LEFT}
                mainMenuOpen={optionsOpened}
                label={
                  <>
                    <FolderIcon size="16px" style={{ marginRight: '8px' }} />
                    <Option>Move to folder</Option>
                  </>
                }
              >
                {folderPropsTree.map(of(FolderMenuItem))}
              </NestedMenuItem>
            </Guard.Component>
          )}
          <MenuItem onClick={onDuplicateWheel} className="py-2">
            <StyledCopyIcon />
            <Option>Duplicate</Option>
          </MenuItem>
          <MenuItem onClick={onInviteToWheel} className="py-2">
            <StyledInvitePlusIcon />
            <Option>Invite to wheel</Option>
          </MenuItem>
          {numberOfAdmins === 1 ? (
            <MenuItem onClick={onDelete} className="py-2">
              <StyledTrashIcon className="mr-lg-2" />
              <Option color="#fb463b">Delete</Option>
            </MenuItem>
          ) : (
            <MenuItem onClick={onLeaveWheel} className="py-2">
              <FiLogOut color="#fb463b" className="mr-lg-2" />
              <Option color="#fb463b">Leave the wheel</Option>
            </MenuItem>
          )}
        </Menu>
      )}

      {(wheel.isMember || wheel.isAccountabilityBuddy) && (
        <Menu
          anchorEl={optionsRef.current}
          open={optionsOpened}
          onClick={(e) => e.stopPropagation()}
          onClose={() => setOptionsOpened(false)}
          MenuListProps={{ disablePadding: true, style: { minWidth: '200px' } }}
          disableAutoFocusItem
        >
          <Guard.Component allow={[AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE]}>
            <NestedMenuItem
              direction={Direction.LEFT}
              mainMenuOpen={optionsOpened}
              label={
                <>
                  <FolderIcon size="16px" style={{ marginRight: '8px' }} />
                  <Option>Move to folder</Option>
                </>
              }
            >
              {folderPropsTree.map(of(FolderMenuItem))}
            </NestedMenuItem>
          </Guard.Component>
          <MenuItem onClick={onDuplicateWheel} className="py-2">
            <StyledCopyIcon />
            <Option>Duplicate</Option>
          </MenuItem>
          {wheel.isMember && (
            <MenuItem onClick={onInviteToWheel} className="py-2">
              <StyledInvitePlusIcon />
              <Option>Invite to wheel</Option>
            </MenuItem>
          )}
          <MenuItem onClick={onLeaveWheel} className="py-2">
            <FiLogOut color="#fb463b" className="mr-lg-2" />
            <Option color="#fb463b">Leave the wheel</Option>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

const StyledBsThreeDotsVertical = styled(BsThreeDotsVertical).withConfig({
  shouldForwardProp: (prop) => !['selected', 'personal'].includes(prop),
})<{ selected: boolean; personal: boolean } & IconBaseProps>`
  color: ${({ selected, personal }) => (personal ? 'transparent' : selected ? '#fb463b' : '#152b3e')};
`;
