import React, { Component } from 'react';
import {
  AccountPlan,
  AggregationUnit,
  iCheckinsAggregation,
  iCommentsAggregation,
  iUser,
  iWheel,
  iWheelMember,
  iWheelPermissions,
} from '../../../../API/interfaces';
import { iTabChildProps } from '../../../Shared/TabContainer/TabContainer';
import { educationService } from '../../../Education/Education.service';
import { analytics } from '../../../../analytics/analytics';
import SegmentTags from '../../common/SegmentTags/SegmentTags';
import SaveShare from '../../common/SaveShare/SaveShare';
import Wheel from '../../common/Wheel/Wheel';
import HistoryChart from '../../../Shared/HistoryChart/HistoryChart';
import CommentsSection from '../../CommentsSection/CommentsSection';
import MemberDropdown from './MemberDropdown/MemberDropdown';
import NoMemberResults from './NoMemberResults/NoMemberResults';
import { MembersClient } from '../../../../API/members.client';
import DateRangeDropdown, {
  DateRangeLabel,
  iDateRangeOptionData,
} from '../../common/DateRangeDropdown/DateRangeDropdown';
import GenerateReport from '../../common/GenerateReportModal/GenerateReport';
import AggregationService from '../../common/AggregationService';
import DeleteCheckin from './DeleteCheckin';
import './MemberResults.scss';
import { Padding } from '../../../Shared/Padding/Padding';
import { TabInfoCard } from '../../TabInfoCard';
import ControlButton from '../../common/ControlButton/ControlButton';
import CheckInAssistIcon from '../../../../Assets/images/checkin-assist.png';
import { AssistMember } from '../../WheelPage';

interface iMemberResultsProps extends iTabChildProps {
  wheel: iWheel;
  wheelPermissions: iWheelPermissions;
  members: Array<iWheelMember>;
  isAccountabilityBuddy?: boolean;
  setActiveBuddy?: (buddy: iWheelMember) => void;
  initiatorId?: string;
  isAdmin?: boolean;
  openCheckinAssistModal?: (member: AssistMember) => void;
  user?: iUser;
  activeMemberResultsMemberId?: string;
}

interface iMemberResultsState {
  activeSegmentId: string | null;
  activeSegmentIndex: number | null;
  activeCheckinDate: string | null;
  activeCheckin: iCheckinsAggregation | null;
  activeMemberId: string | null;
  members: Array<iWheelMember> | null;
  checkins: Array<iCheckinsAggregation> | null;
  comments: iCommentsAggregation | null;
  dateRange: iDateRangeOptionData | null;
  showOnlyCommentsWithText: boolean;
  showEmptyCheckins: boolean;
}

class MemberResults extends Component<iMemberResultsProps, iMemberResultsState> {
  private readonly aggregationService: AggregationService;

  constructor(props) {
    super(props);

    this.state = {
      activeSegmentId: null,
      activeSegmentIndex: null,
      activeCheckinDate: null,
      activeCheckin: null,
      activeMemberId: null,
      members: null,
      checkins: null,
      comments: null,
      dateRange: {
        label: 'Latest check ins' as DateRangeLabel,
        from: null,
        to: null,
        unit: 'none' as AggregationUnit,
      },
      showOnlyCommentsWithText: false,
      showEmptyCheckins: false,
    };

    this.aggregationService = new AggregationService(props.wheel);
  }

  setActiveSegmentId = (segmentId, segmentIndex) => {
    this.setState({ activeSegmentId: segmentId, activeSegmentIndex: segmentIndex });
  };

  setActiveCheckinDate = (checkinCreateDate) => {
    const { activeCheckinDate } = this.state;
    this.setState({ activeCheckinDate: activeCheckinDate === checkinCreateDate ? null : checkinCreateDate });
  };

  setActiveMemberId = (memberId: string) => this.setState({ activeMemberId: memberId });

  setDateRange = (dateRange: iDateRangeOptionData) => {
    this.setState({ dateRange });
  };

  // change filter for comments
  setShowOnlyCommentsWithText = (showOnlyCommentsWithText: boolean) => {
    this.setState({ showOnlyCommentsWithText });
  };

  dateRangeDropdownCb = (dateRange: iDateRangeOptionData) => {
    this.setDateRange(dateRange);
    analytics.dateRangeDropdown('memberResults', dateRange.label);
  };

  loadData = async () => {
    const { setIsLoading, wheel, isAccountabilityBuddy } = this.props;
    const { activeMemberId, dateRange, activeSegmentId, showOnlyCommentsWithText } = this.state;

    setIsLoading(true);
    try {
      const checkinsResponse = await this.aggregationService.loadData({
        wheelId: wheel.id,
        userId: activeMemberId,
        from: dateRange.from?.toISODate(),
        to: dateRange.to?.toISODate(),
        unit: dateRange.unit,
      });

      const checkins = this.aggregationService.mapCheckinsResponse(checkinsResponse);
      const lastCheckin = checkins[0];

      this.setState(
        {
          checkins,
          activeCheckin: lastCheckin || null,
          activeCheckinDate: lastCheckin?.dateRange || null,
          comments: this.aggregationService.getActiveComments(checkins, showOnlyCommentsWithText, activeSegmentId),
        },
        () => setIsLoading(false)
      );
    } catch {
      if (isAccountabilityBuddy) {
        this.setState({ showEmptyCheckins: true });
        setIsLoading(false);
      }
    }
  };

  shouldShowDeleteCheckinBtn = (): boolean => {
    const { dateRange, activeSegmentId, activeCheckinDate } = this.state;
    // latest results && all segments && only one bar selected
    return dateRange.unit === 'none' && !activeSegmentId && !!activeCheckinDate;
  };

  componentDidUpdate(prevProps: Readonly<iMemberResultsProps>, prevState: Readonly<iMemberResultsState>) {
    const { wheel, initiatorId } = this.props;
    const {
      activeCheckinDate,
      checkins,
      activeSegmentId,
      activeMemberId,
      dateRange,
      showOnlyCommentsWithText,
    } = this.state;

    // change data when member is changed
    if (!!initiatorId && prevProps.initiatorId !== initiatorId) {
      this.setState({ activeMemberId: initiatorId });
    }
    if (prevState.activeMemberId !== activeMemberId || dateRange !== prevState.dateRange) {
      this.loadData();
    } else {
      if (prevState.activeCheckinDate !== activeCheckinDate) {
        this.setState({
          activeCheckin: this.aggregationService.getActiveCheckin(checkins, activeCheckinDate),
        });
      }

      // set comment when segment or comments filter is changed
      if (
        (wheel.isScoreComments && prevState.activeSegmentId !== activeSegmentId) ||
        prevState.showOnlyCommentsWithText !== showOnlyCommentsWithText
      ) {
        this.setState({
          comments: this.aggregationService.getActiveComments(checkins, showOnlyCommentsWithText, activeSegmentId),
        });
      }
    }
  }

  async componentDidMount() {
    const { wheel, setIsLoading, setActiveBuddy, initiatorId, activeMemberResultsMemberId } = this.props;

    setIsLoading(true);
    const response = await MembersClient.getWheelMembersByWheelId(wheel.id);
    if (!response || !response.data) {
      return;
    }

    const members: Array<iWheelMember> = response.data
      .map((member) => {
        const wheelMember = wheel.members.find((wm) => wm.userId === member.userId);
        return {
          ...member,
          isPasswordReset: wheelMember?.isPasswordReset || false,
          isManuallyAddedUser: wheelMember?.isManuallyAddedUser || false,
          isManuallyCreatedUser: wheelMember?.isManuallyCreatedUser || false,
        };
      })
      .sort((a, b) => {
        return a.lastCheckinDate ? (b.lastCheckinDate ? b.lastCheckinDate.localeCompare(a.lastCheckinDate) : -1) : 1;
      });

    const activeMemberId = initiatorId || activeMemberResultsMemberId || members[0].userId;

    this.setState({ members, activeMemberId }, () => {
      setActiveBuddy(members[0]);
      setIsLoading(false);
      this.loadData().then(() => educationService.processMembersTabEducation());
    });
  }

  render() {
    const {
      wheel,
      setIsLoading,
      wheelPermissions,
      members,
      isAccountabilityBuddy,
      setActiveBuddy,
      isAdmin = false,
      openCheckinAssistModal,
      user,
    } = this.props;
    const {
      activeCheckin,
      activeSegmentId,
      activeMemberId,
      activeCheckinDate,
      checkins,
      activeSegmentIndex,
      comments,
      dateRange,
      showOnlyCommentsWithText,
      showEmptyCheckins,
    } = this.state;

    if (!checkins) {
      return isAccountabilityBuddy && showEmptyCheckins ? (
        <TabInfoCard title="You have been removed as an Accountability buddy from this wheel" />
      ) : null;
    }

    const refresh = () => {
      return this.loadData();
    };

    if (!checkins.length) {
      return isAccountabilityBuddy ? (
        <TabInfoCard
          title="Your buddy has not completed a check in yet!"
          subtitle="Please reach out to your check in buddy and remind them to complete a check in so that you can see the results!"
        />
      ) : (
        <NoMemberResults
          wheel={wheel}
          members={members}
          activeMemberId={activeMemberId}
          setActiveMemberId={this.setActiveMemberId}
          dateRange={dateRange}
          setDateRange={this.setDateRange}
          shouldShowDateDropdown={dateRange.label !== 'Latest check ins'}
        />
      );
    }

    const activeSegmentName = activeSegmentIndex !== null ? activeCheckin.scores[activeSegmentIndex].segmentName : null;

    const isCheckinAssistButtonShown = isAdmin && user._id !== activeMemberId;

    const onCheckinAssist = () => {
      const activeMember = members.find((m) => m.userId === activeMemberId);
      openCheckinAssistModal({
        id: activeMemberId,
        name: `${activeMember.firstName} ${activeMember.lastName}`,
      });
      analytics.openMemberResultsCheckinAssist();
    };

    return (
      <>
        <div className="member-results">
          <SegmentTags
            averageScore={activeCheckin.averageScore}
            scores={activeCheckin.scores}
            activeSegmentId={activeSegmentId}
            onClick={this.setActiveSegmentId}
          />

          <div className="buttons-container">
            <div className="checkin-controls">
              <SaveShare wheel={wheel} setIsLoading={setIsLoading} memberId={activeMemberId} collapsable={true} />

              {this.shouldShowDeleteCheckinBtn() && !isAccountabilityBuddy && (
                <DeleteCheckin
                  wheelId={wheel.id}
                  checkinId={activeCheckin.checkinIds?.[0]}
                  member={members.find((m) => m.userId === activeMemberId)}
                  onDelete={() => this.loadData()}
                />
              )}

              {isCheckinAssistButtonShown && (
                <ControlButton Icon={CheckInAssistIcon} label="Check in assist" width={105} onClick={onCheckinAssist} />
              )}
            </div>

            <div className="timeline-controls">
              <MemberDropdown
                className="education_member-dropdown"
                members={this.state.members}
                activeMemberId={activeMemberId}
                setActiveMemberId={this.setActiveMemberId}
                teamAlertThreshold={wheel.wheelCreator.account?.teamAlertThreshold}
                setActiveBuddy={setActiveBuddy}
              />
              <DateRangeDropdown activeOption={dateRange} onChange={this.dateRangeDropdownCb} />
              <GenerateReport
                isMemberResults
                lockFeature={!wheelPermissions?.wheel_pdfReport}
                wheelPermissions={wheelPermissions}
                wheel={wheel}
                dateRange={dateRange}
                numberOfMembers={1}
                member={members.find((m) => m.userId === activeMemberId)}
                setIsLoading={setIsLoading}
                activeSegmentId={activeSegmentId}
                activeSegmentName={activeSegmentName}
                activeCheckinDate={activeCheckinDate}
                showOnlyCommentsWithText={showOnlyCommentsWithText}
              />
            </div>
          </div>

          <div className="charts-container">
            <Wheel
              maxScale={wheel?.maxScale}
              averageScore={activeCheckin.averageScore}
              scores={activeCheckin.scores}
              activeSegmentId={activeSegmentId}
              onClick={this.setActiveSegmentId}
              width={300}
              numberOfCheckins={activeCheckin.numberOfCheckins}
            />

            <HistoryChart
              key={activeMemberId} // update chart when user is changed
              checkins={checkins}
              activeSegmentId={activeSegmentId}
              activeSegmentIndex={activeSegmentIndex}
              wheelScale={wheel.maxScale}
              onClick={this.setActiveCheckinDate}
              activeCheckinDate={activeCheckinDate}
            />
          </div>
        </div>
        <Padding mPadding={'0px'}>
          {(activeSegmentIndex === null || wheel.isScoreComments) && (
            <CommentsSection
              activeSegmentName={activeSegmentName}
              activeCheckinDate={activeCheckinDate}
              dateRange={dateRange}
              comments={comments}
              members={this.state.members}
              refresh={refresh}
              numberOfCheckins={activeCheckin.numberOfCheckins}
              showOnlyCommentsWithText={showOnlyCommentsWithText}
              setShowOnlyCommentsWithText={this.setShowOnlyCommentsWithText}
              teamAlertThreshold={wheel.wheelCreator.account?.teamAlertThreshold}
              isReplyEnabled={
                wheel.wheelCreator.account.plan === AccountPlan.BUSINESS ||
                wheel.wheelCreator.account.plan === AccountPlan.PERFORMANCE
              }
            />
          )}
        </Padding>
      </>
    );
  }
}

export default MemberResults;
