import React, { useState } from 'react';
import { Edit3, Trash2 } from 'react-feather';
import './components.scss';
import { DeliveryMethod } from '../../../../../API/tasks.client';
import { TaskView } from '../TaskReminder';
import TaskPills from './TaskPills';
import { Text } from '../../../../Shared/Typography/Typography';
import TaskCompletionToggle from './TaskCompletionToggle';
import AssigneeBadge from './AssigneeBadge';

export interface TaskItemProps {
  task: {
    taskId: string;
    title: string;
    description: string;
    dueDateTime: Date;
    recurrence: string;
    deliveryMethods?: DeliveryMethod[];
    assignees?: Array<{ userId: string; completed: boolean }>;
    completed?: boolean;
  };
  members: Array<{ userId: string; firstName: string; lastName: string }>;
  onEdit?: () => void;
  onDelete?: () => void;
  isAdmin: boolean;
  userId: string;
  view: TaskView;
  currentFilter?: string;
}

const TaskItem: React.FC<TaskItemProps> = ({
  task,
  members,
  onEdit,
  onDelete,
  isAdmin,
  userId,
  view,
  currentFilter,
}) => {
  const [showActions, setShowActions] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(task.completed);

  const isTodoView = view === TaskView.TODO;
  const isHistoryView = view === TaskView.HISTORY;
  const showHistoryPills = isHistoryView && currentFilter !== 'all';
  const showHistoryBadges = isHistoryView && isAdmin && currentFilter === 'all';

  const getIsEditable = () => {
    if (isAdmin && currentFilter === 'all') return false;
    if (isAdmin) return currentFilter === userId;
    return !isAdmin;
  };

  const handleStatusChange = (newStatus: boolean) => {
    setTaskCompleted(newStatus);
  };

  const getCompletionStatus = () => {
    if (isAdmin && currentFilter === 'all') {
      const allCompleted = task.assignees?.every((assignee) => assignee.completed);
      if (isHistoryView) return allCompleted ? 'complete' : 'incomplete';
      return allCompleted ? 'complete' : null;
    }

    return taskCompleted ? 'complete' : 'incomplete';
  };

  const shouldShowTaskCompletionToggle = () => {
    const completionStatus = getCompletionStatus();
    return !(isAdmin && currentFilter !== userId && completionStatus === 'incomplete' && isTodoView);
  };

  const renderAssigneeBages = () => {
    if (!task.assignees || task.assignees.length === 0) {
      return null;
    }

    const uniqueAssignees = Array.from(new Map(task.assignees.map((a) => [a.userId, a])).values());

    return (
      <div className="badges-history">
        {uniqueAssignees.map((assignee) => {
          const member = members.find((m) => m.userId === assignee.userId);
          return (
            <AssigneeBadge
              key={`${assignee.userId}-${task.taskId}`}
              userId={assignee.userId}
              firstName={member?.firstName}
              lastName={member?.lastName}
              isCompleted={assignee.completed}
              view={view}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="task-item" onMouseEnter={() => setShowActions(true)} onMouseLeave={() => setShowActions(false)}>
      <div className="task-item-group">
        <div className="task-checkbox-group">
          {shouldShowTaskCompletionToggle() && getCompletionStatus() !== null && (
            <TaskCompletionToggle
              taskId={task.taskId}
              dueDateTime={task.dueDateTime}
              completed={taskCompleted}
              isEditable={getIsEditable()}
              onStatusChange={handleStatusChange}
              view={view}
              taskStatus={getCompletionStatus()}
            />
          )}
          <div className="task-details">
            <Text size="16px" weight="400" className={taskCompleted && isTodoView ? 'task-title-complete' : ''}>
              {task.title}
            </Text>
            {!isHistoryView && task.description && (
              <Text size="14px" weight="400" color="#6c757d">
                {task.description}
              </Text>
            )}
            {(showHistoryPills || isTodoView) && (
              <div className="pills" style={{ marginTop: '8px' }}>
                {['dueDateTime', 'recurrence', 'deliveryMethods', 'assignees'].map((pillType) => (
                  <TaskPills
                    key={pillType}
                    type={pillType as any}
                    taskData={task}
                    members={members}
                    userId={userId}
                    isAdmin={isAdmin}
                    view={view}
                    currentFilter={currentFilter}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {isAdmin && showActions && isTodoView && (
          <div className="task-actions">
            {
              <button className="history-btn" onClick={onEdit}>
                <Edit3 color="#2b2e31" strokeWidth="2" size="14" />
                Edit
              </button>
            }

            <Trash2 color="#fb463b" strokeWidth="2" size="16" onClick={onDelete} />
          </div>
        )}
      </div>
      {showHistoryBadges && renderAssigneeBages()}
    </div>
  );
};

export default TaskItem;
