import React, { useEffect, useState } from 'react';
import { AuthContainer } from './AuthContainer';
import './Login.scss';
import services from '../../API/services';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Toast } from '../Shared/Toaster/Toast';
import { Text } from '../Shared/Typography/Typography';
import { analytics } from '../../analytics/analytics';
import { ToastTypes } from '../Shared/Toaster/Toaster.actions';
import { authService } from './Auth.service';
import { selectInvitationLink } from '../Invitations/invitation.selectors';
import { resetInvitationLink } from '../Invitations/invitation.actions';
import { SignInButtonMS } from './SSO/msal/SignInButtonMS';
import { Divider, DividerContent } from './SSO/Divider';
import { SignInButtonOkta } from './SSO/okta/SignInButtonOkta';
import { AccountPlan, AccountRole, AccountType, iUser } from '../../API/interfaces';
import { RootState } from '../../Redux/Reducers';
import { getIpAddressAction } from './Auth.actions';
import { AuthContentBox, BtnGroup, BtnsGroup, LeftPanel, RedBtn } from './Onboarding/_styled';
import { TextField } from '@material-ui/core';
import eye from '../../Assets/images/onboarding/eye.svg';
import eyeOff from '../../Assets/images/onboarding/eye-off.svg';

interface iLoginProps extends RouteComponentProps {
  setUser: (user) => void;
  user: any;
}

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().email('Not valid email format').required('The field can’t be empty'),
  password: yup.string().min(6, 'Password must contain at least 6 characters').required('The field can’t be empty'),
});

const LoginComponent = ({ setUser, location, ...props }: iLoginProps) => {
  const [serverError, setServerError] = useState('');
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const history = useHistory();
  const invitationLink = useSelector(selectInvitationLink);
  const dispatch = useDispatch();
  const shareLinkParams = useSelector((state: RootState) => (state.shareLink ? state.shareLink.joinParams : ''));

  const tagParam = new URLSearchParams(location.search).get('tag') as AccountType | null;

  const shouldRedirectToInsights = (user: iUser) =>
    (user?.accountRole === AccountRole.ADMIN ||
      user?.accountRole === AccountRole.MANAGER ||
      user?.accountRole === AccountRole.TEACHER) &&
    (user?.account?.plan === AccountPlan.BUSINESS ||
      user?.account?.plan === AccountPlan.SCHOOL ||
      user?.account?.plan === AccountPlan.PERFORMANCE);

  useEffect(() => {
    if (props.user && props.user._id) {
      if (shouldRedirectToInsights(props.user)) history.push('/insights');
      else history.push('/dashboard');
    }
  }, []);

  const submit = async (values) => {
    const response = await services.userLogin(values);
    if (response.status === 200) {
      authService.login({
        token: response.data.access_token,
        ...response.data.user,
      });
      await services
        .getIPAddress()
        .then(({ data }) => dispatch(getIpAddressAction(data)))
        .catch((error) => error);
      processRedirect({ ssoSignup: false, user: response.data.user });
    } else {
      setServerError(response.data.message);
    }
  };

  const submitSSO = async (response) => {
    if (response.status === 200) {
      authService.login({
        token: response.data.access_token,
        ...response.data.user,
      });
      analytics.loginSSO();
      processRedirect({ ssoSignup: response.data.signup, user: response.data.user });
    } else {
      setServerError(response.data.message);
    }
  };

  const processRedirect = ({ ssoSignup, user }: { ssoSignup: boolean; user: iUser }) => {
    if (ssoSignup) {
      history.push('/onboarding');
    } else if (invitationLink) {
      dispatch(resetInvitationLink());
      history.push(invitationLink);
    } else if (shareLinkParams) {
      history.push(`/share${shareLinkParams}&step=browser`);
    } else {
      history.push('/dashboard');
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  const signUpLinkEvent = () => {
    analytics.signUpLink();
  };

  const forgotPasswordEvent = () => {
    analytics.forgotPasswordLink();
  };

  const buildSignUpLink = () => {
    switch (tagParam) {
      case AccountType.GT_CONNECT:
        return '/sign-up?tag=gt_connect';
      case AccountType.NO_STRESS:
        return '/sign-up?tag=no_stress_mate';
      case AccountType.ATHLETIC_WEST:
        return '/sign-up?tag=athletic_west';
      case AccountType.SKILLHIRE:
        return '/sign-up?tag=skillhire';
      case AccountType.WUNAN:
        return '/sign-up?tag=wunan';
      case AccountType.METATTUDE:
        return '/sign-up?tag=metattude';
      default:
        return '/sign-up';
    }
  };

  return (
    <AuthContainer>
      <div className="login">
        {serverError && (
          <div className="mb-4">
            <Toast type={ToastTypes.ERROR} message={serverError} />
          </div>
        )}
        <LeftPanel style={{ width: '100%' }}>
          <AuthContentBox>
            <Text weight={600} size="36px">
              Log in
            </Text>
            <div className="mb-4">
              <Text size="14px">
                Don't have an account yet?{' '}
                <Link className="login_signup-link" to={buildSignUpLink()} onClick={signUpLinkEvent}>
                  Sign Up
                </Link>
              </Text>
            </div>
            <BtnGroup>
              <SignInButtonMS submitSSO={submitSSO}>Microsoft</SignInButtonMS>
              <SignInButtonOkta submitSSO={submitSSO} />
            </BtnGroup>
            <div>
              <Divider>
                <DividerContent>or</DividerContent>
              </Divider>
            </div>
            <form onSubmit={formik.handleSubmit} style={{ marginTop: '34px' }}>
              <div className="mb-2 d-flex flex-column" style={{ gap: '30px' }}>
                <TextField
                  id="email-login"
                  label="Email"
                  type="email"
                  variant="outlined"
                  placeholder="Enter Your Email"
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  id="password-login"
                  label="Password"
                  placeholder="Enter Your Password"
                  type={showLoginPassword ? 'text' : 'password'}
                  variant="outlined"
                  value={formik.values.password || ''}
                  onChange={formik.handleChange('password')}
                  onBlur={formik.handleBlur('password')}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <img
                        style={{ cursor: 'pointer', padding: '0 10px' }}
                        src={showLoginPassword ? eye : eyeOff}
                        onClick={() => {
                          setShowLoginPassword(!showLoginPassword);
                        }}
                      />
                    ),
                  }}
                />
              </div>
              <div className="mb-3">
                <Link className="login_forgot-link" to="/reset-password-start" onClick={forgotPasswordEvent}>
                  Forgot password?
                </Link>
              </div>
              {!formik.errors.email && !formik.errors.password && <RedBtn type="submit">Login</RedBtn>}
            </form>
          </AuthContentBox>
        </LeftPanel>
      </div>
    </AuthContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userRD.user ? state.userRD.user : {},
  };
};

const LoginWithRouter = withRouter(LoginComponent);

export const Login = connect(mapStateToProps, null)(LoginWithRouter);
