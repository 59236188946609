import React from 'react';
import dayjs from 'dayjs';
import { iWheelMember } from '../../../../../API/interfaces';
import TaskItem from '../components/TaskItem';
import AssigneesFilter from '../components/AssigneesFilter';
import { Text } from '../../../../Shared/Typography/Typography';
import { Inbox, Plus } from 'react-feather';
import { TaskView } from '../TaskReminder';
import './TodoList.scss';
import NoTask from '../components/NoTasks';
import { Spinner, SpinnerBackground } from '../../../../Shared/Spinner/Spinner';
import { toasterService } from '../../../../Shared/Toaster/Toaster.service';

interface TodoListProps {
  userId: string;
  members: iWheelMember[];
  isAdmin: boolean;
  fetchTasks: (filter: string) => void;
  editTask: (taskId: string) => void;
  deleteTask: (taskId: string) => void;
  openHistory: () => void;
  createTask: () => void;
  tasks: any[];
  isLoading: boolean;
  view: TaskView;
  currentFilter: string;
}

const TodoList: React.FC<TodoListProps> = ({
  userId,
  members,
  isAdmin,
  fetchTasks,
  editTask,
  deleteTask,
  openHistory,
  createTask,
  tasks,
  isLoading,
  view,
  currentFilter,
}) => {
  const today = dayjs().startOf('day');
  const todayTasks = tasks.filter((task) => dayjs(task.dueDateTime).isSame(today, 'day'));
  const upcomingTasks = tasks.filter((task) => dayjs(task.dueDateTime).isAfter(today, 'day'));

  const handleDeleteTask = (task) => {
    deleteTask(task.taskId);
    toasterService.addSuccessToast(`The task '${task.title}' has been successfully deleted!`);
  };

  return (
    <div className="todo-list">
      <div className="header">
        <div className="todo-list-btn-group">
          <Text size="18px" weight="500">
            To-Do List
          </Text>
          {isAdmin && (
            <button className="create-btn" onClick={createTask}>
              <Plus color="#fff" strokeWidth="2" size="18" />
              Create Task
            </button>
          )}
        </div>
        <div className="todo-list-btn-group">
          <button className="history-btn" onClick={openHistory}>
            <Inbox color="#2b2e31" strokeWidth="2" size="14" />
            History
          </button>
          {isAdmin && (
            <AssigneesFilter userId={userId} members={members} fetchTasks={fetchTasks} currentFilter={currentFilter} />
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="task-loader">
          <SpinnerBackground height="60px">
            <Spinner />
          </SpinnerBackground>
        </div>
      ) : (
        <>
          {todayTasks.length > 0 ? (
            todayTasks.map((task) => (
              <TaskItem
                key={task.taskId}
                task={task}
                members={members}
                onEdit={() => editTask(task.taskId)}
                onDelete={() => handleDeleteTask(task)}
                isAdmin={isAdmin}
                userId={userId}
                view={view}
                currentFilter={currentFilter}
              />
            ))
          ) : (
            <NoTask message="There are no tasks in the to-do list at the moment" showImage />
          )}

          <Text size="14px" weight="300" color="#2a3e50" style={{ display: 'block', margin: '24px 0 16px' }}>
            Upcoming Tasks
          </Text>
          {upcomingTasks.length > 0 ? (
            <>
              {upcomingTasks.map((task) => (
                <TaskItem
                  key={task.taskId}
                  task={task}
                  members={members}
                  onEdit={() => editTask(task.taskId)}
                  onDelete={() => handleDeleteTask(task)}
                  isAdmin={isAdmin}
                  userId={userId}
                  view={view}
                  currentFilter={currentFilter}
                />
              ))}
            </>
          ) : (
            <NoTask message="There are no upcoming tasks. Add one to plan ahead and stay organised." />
          )}
        </>
      )}
    </div>
  );
};

export default TodoList;
