import React, { useState } from 'react';
import { AuthContainer } from './AuthContainer';
import './ResetPasswordComplete.scss';
import { Button } from '../Shared/Buttons/Button';
import services from '../../API/services';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Text } from '../Shared/Typography/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Toast } from '../Shared/Toaster/Toast';
import { analytics } from '../../analytics/analytics';
import { ToastTypes } from '../Shared/Toaster/Toaster.actions';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { authService } from './Auth.service';
import { BlackBtn, BlackButtonBox, LeftPanel } from './Onboarding/_styled';
import { ArrowLeft } from 'react-feather';
import { TextField } from '@material-ui/core';

type iSetNewPasswordProps = RouteComponentProps;

const initialValues = {
  password: '',
};

const validationSchema = yup.object({
  password: yup.string().min(6, 'Password must contain at least 6 characters').required('The field can’t be empty'),
});

const ResetPasswordCompleteComponent = (props: iSetNewPasswordProps) => {
  const [serverError, setServerError] = useState('');
  const parseQueryParams = (): {
    email: string | null;
    resetCode: string | null;
  } => {
    const query = new URLSearchParams(props.location.search);
    let resetCode = query.get('resetCode');
    let email = query.get('email');

    if (email) {
      email = email.replace(/ /g, '+');
    }

    return {
      email,
      resetCode,
    };
  };

  const { email, resetCode } = parseQueryParams();

  const login = async (email, password, redirectPath = '/dashboard') => {
    const loginResponse = await services.userLogin({ email, password });
    if (loginResponse.status === 200) {
      authService.login({
        token: loginResponse.data.access_token,
        ...loginResponse.data.user,
      });
      toasterService.addSuccessToast('Your password is successfully changed!');
      props.history.push(redirectPath);
    } else {
      setServerError(loginResponse.data.message);
    }
  };

  const handleSubmit = async ({ password }) => {
    if (email && resetCode) {
      const response = await services.resetPasswordComplete({
        email,
        password,
        resetCode,
      });
      if (response.status === 200) {
        const { isManuallyAddedUser } = response.data;
        const redirectPath = isManuallyAddedUser ? '/onboarding' : '/dashboard';

        await login(email, password, redirectPath);
        analytics.saveNewPassword();
      } else {
        setServerError(response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <AuthContainer>
      <div className="set-password">
        {serverError && (
          <div className="mb-4">
            <Toast type={ToastTypes.ERROR} message={serverError} />
          </div>
        )}
        <Link to="/login">
          <BlackButtonBox>
            <BlackBtn>
              <ArrowLeft size={13} /> BACK
            </BlackBtn>
          </BlackButtonBox>
        </Link>
        <LeftPanel style={{ width: '100%' }}>
          <div className="mb-4">
            <Text weight={600} size="36px">
              Create Password
            </Text>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <TextField
                id="set-password_input"
                label="Password"
                placeholder="Enter Your New Password"
                variant="outlined"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                style={{ width: '100%', maxWidth: '400px' }}
              />
            </div>
            <div>
              <Button type="submit" disabled={!(formik.isValid && formik.dirty)}>
                Save Password
              </Button>
            </div>
          </form>
        </LeftPanel>
      </div>
    </AuthContainer>
  );
};

export const ResetPasswordComplete = withRouter(ResetPasswordCompleteComponent);
